import React from 'react';
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import Charts from '../components/Charts'; // Import the Charts component

const Dashboard = () => {
    return (
        <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
            {/* Sidebar */}
            <Sidebar />

            {/* Main Content */}
            <div className="flex-1 flex flex-col">
                <TopBar />

                {/* Main Content Area */}
                <main className="flex-1 p-6 overflow-y-auto">
                    <h2 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">Dashboard</h2>

                    {/* Cards Section */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
                        {/* Total Revenue */}
                        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md flex items-center justify-between">
                            <div>
                                <h3 className="text-sm font-semibold text-gray-500 dark:text-gray-400">Total Revenue</h3>
                                <p className="text-3xl font-bold text-gray-900 dark:text-white">$10,000</p>
                            </div>
                            <div className="text-green-500 text-3xl dark:text-green-300">
                                💰
                            </div>
                        </div>

                        {/* Monthly Visitors */}
                        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md flex items-center justify-between">
                            <div>
                                <h3 className="text-sm font-semibold text-gray-500 dark:text-gray-400">Monthly Visitors</h3>
                                <p className="text-3xl font-bold text-gray-900 dark:text-white">2,300</p>
                            </div>
                            <div className="text-blue-500 text-3xl dark:text-blue-300">
                                👥
                            </div>
                        </div>
                        {/* New Users */}
                        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md flex items-center justify-between">
                            <div>
                                <h3 className="text-sm font-semibold text-gray-500 dark:text-gray-400">New Users</h3>
                                <p className="text-3xl font-bold text-gray-900 dark:text-white">120</p>
                            </div>
                            <div className="text-yellow-500 text-3xl dark:text-yellow-300">
                                🆕
                            </div>
                        </div>

                        {/* Active Subscriptions */}
                        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md flex items-center justify-between">
                            <div>
                                <h3 className="text-sm font-semibold text-gray-500 dark:text-gray-400">Active Subscriptions</h3>
                                <p className="text-3xl font-bold text-gray-900 dark:text-white">500</p>
                            </div>
                            <div className="text-red-500 text-3xl dark:text-red-300">
                                🔥
                            </div>
                        </div>
                    </div>

                    {/* Charts or other content */}
                    <Charts />
                </main>

            </div>
        </div>
    );
};

export default Dashboard;
