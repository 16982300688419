// Alert.js
import React from 'react';

const Alert = ({ message, onClose }) => {
    return (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-red-500 text-white p-4 rounded shadow-md z-50">
            <span>{message}</span>
            <button onClick={onClose} className="ml-4 text-white font-bold">
                X
            </button>
        </div>
    );
};

export default Alert;
