import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const WebCard = ({ ImageHref, title, description, button, buttonHref }) => {
  return (
    <>
      <div
        className={`w-full ring-2 bg-gray-50 ring-gray-100 hover:shadow-lg pt-5 px-5 rounded-md`}
      >
        <div className="relative mb-12">
          <div className="overflow-hidden rounded-[10px]">
            <img src={ImageHref} alt="portfolio" className="w-full" />
          </div>
          <div className="relative z-10 mx-7 -mt-20 rounded-lg bg-gray-50 ring-1 ring-gray-100 py-[34px] px-3 text-center">
            <h3 className="mb-5 text-xl font-bold">{title}</h3>
            <p className="mb-4 text-gray-700 italic text-opacity-80 text-base leading-8 text-justify">
              {description}
            </p>
            <a
              href={buttonHref}
              target="_blank"
              rel="noreferrer"
              className="hover:bg-blue-500 inline-block rounded-md border border-stroke py-[10px] px-7 text-sm font-medium transition hover:text-white"
            >
              {button}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default function TradeSolutions() {
  return (
    <div>
      <Header />
      <div className="pt-24 md:pt-36 text-center">
        <span className="bg-clip-text text-xl md:text-3xl font-bold text-transparent bg-gradient-to-r from-primary to-accent">
          Trade Solutions
        </span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 px-5 gap-10 my-10">
        <WebCard
          ImageHref="assets/btfn.PNG"
          title="Development and Implementation of Bhutan (BTFN)"
          description="The Bhutan TradeFin Net (BTFN) System records and measures all cross-border transactions on real time basis. With a single submission of documents to multiple regulatory agencies through a single integrated platform of the BTFN, the cross-border transactions can be facilitated, fulfilling the trade formalities of the traders."
          button="Visit site"
          buttonHref="https://www.btfn.bt/"
        />
        <WebCard
          ImageHref="assets/ecms.PNG"
          title="Electronic Custom Management System (eCMS)"
          description="eCMS is a web-based application, that is accessible to all citizens and businesses wishing to conduct international trade that will consist, inter alia, of Customs Agents (Brokers), Importers, Exporters, freight forwarders and private citizens via the internet."
          button="Visit site"
          buttonHref="https://www.ecms.gov.bt/"
        />
      </div>
      <Footer />
    </div>
  );
}
