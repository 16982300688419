import React, { useState } from 'react';
import { auth, googleProvider, firestoreDb } from "../config/firebase";
import { signInWithPopup, signInWithEmailAndPassword } from "firebase/auth"; // Ensure this import
import { doc, getDoc } from "firebase/firestore"; // Import Firestore methods
import Alert from '../components/Alert'; // Import the Alert component

export const Auth = ({ showPassword, toggleShowPassword }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [alertMessage, setAlertMessage] = useState(null); // State to manage alert message

    const signIn = async (event) => {
        event.preventDefault(); // Prevent form submission
        try {
            const session = await signInWithEmailAndPassword(auth, email, password);
            localStorage.setItem("adminExists", session);
            window.location.href = "/dashboard";
        } catch (err) {
            setAlertMessage("Login Failed: Check Credentials"); // Set alert message
            console.error('Error signing in:', err);
            // Automatically clear the alert after 5 seconds
            setTimeout(() => {
                setAlertMessage(null);
            }, 2000);
        }
    };
    
    const signInWithGoogle = async () => {
        try {
            // Sign in with Google
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;

            // Immediately sign out the user to prevent saving their details in Firebase Authentication
            await auth.signOut();

            // Check if the user's email exists in your custom database (Firestore, Realtime Database, etc.)
            const userDoc = await checkUserEmailInDatabase(user.email);

            if (userDoc.exists) {
                // Email exists in your custom database, so proceed with login
                window.location.href = "/documentDisplay";
            } else {
                // Email does not exist, show an error message
                setAlertMessage("Error: Your email is not registered with us.");
                console.error('User not found in custom database:', user);
            }
        } catch (err) {
            setAlertMessage("Error signing in with Google");
            console.error('Error signing in with Google:', err);
            setTimeout(() => {
                setAlertMessage(null);
            }, 2000);
        }
    };
    
    // Define the function to check if the user's email exists in your custom database
    const checkUserEmailInDatabase = async (email) => {
        try {
            const userRef = doc(firestoreDb, 'users', email); // Firestore document reference
            const userDoc = await getDoc(userRef); // Get the document snapshot
            return userDoc; // Return the document snapshot
        } catch (err) {
            console.error('Error checking user email in database:', err);
            return null;
        }
    };

    return (
        <div className="mt-10 space-y-4">
            {alertMessage && (
                <Alert
                    message={alertMessage}
                    onClose={() => setAlertMessage(null)} // Clear alert on close
                />
            )}
            <form onSubmit={signIn}>
                <input
                    placeholder="Email..."
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full focus:outline-none p-2 rounded-md text-black"
                    type="email"
                />
                <div className="relative my-5">
                    <input
                        placeholder="Password..."
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full focus:outline-none p-2 rounded-md text-black"
                    />
                    <button
                        className="absolute top-1/2 transform -translate-y-1/2 right-2 text-black"
                        onClick={toggleShowPassword}
                        type="button"
                    >
                        <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                    </button>
                </div>
                <button
                    type="submit"
                    className="mb-4 px-6 py-2 bg-blue-500 text-white rounded"
                >
                    Sign In
                </button>
            </form>
            {/* <button
                onClick={signInWithGoogle}
                className="mb-4 px-6 py-2 bg-red-500 text-white rounded"
            >
                <i className="fa-brands fa-google"></i>
            </button> */}
        </div>
    );
};
