import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet
import Footer from "../components/Footer";
import '@react-pdf-viewer/core/lib/styles/index.css'; // Ensure this is imported

const tableStyle = {
  border: '1px solid black',
  borderCollapse: 'collapse',
  width: '100%',
};
const thStyle = {
  border: '1px solid black',
  padding: '8px',
  textAlign: 'left',
};

const tdStyle = {
  border: '1px solid black',
  padding: '8px',
  textAlign: 'left',
};

function JobListings() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Handle viewing PDFs
  const handleViewClick = (url) => {
    const newTab = window.open(`/pdf-viewer?file=${encodeURIComponent(url)}`, '_blank');
    newTab.focus();
  };

  return (
    <div>
      <Helmet>
        <title>Job Listings - Vacancy Announcement For A Business Analyst</title>
        <meta property="og:title" content="Vacancy Announcement For A Business Analyst" />
        <meta property="og:description" content="We are looking for a motivated Business Analyst to join our software team." />
        <meta property="og:image" content="URL_TO_YOUR_IMAGE" /> {/* Replace with the actual image URL */}
        <meta property="og:url" content="YOUR_PAGE_URL" /> {/* Replace with the actual URL of this page */}
        <meta property="og:type" content="website" />
      </Helmet>

      <header className="w-full flex items-center bg-white top-0 fixed justify-between px-4 md:px-10 shadow-lg py-3">
        {/* Header Code */}
        <div className="flex items-center gap-4">
          <Link to="/">
            <img className="h-16 md:h-20" src="assets/logo.PNG" alt="Yangkhor logo" />
          </Link>
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-800 to-blue-300 text-lg md:text-2xl">
            Recruitment System and Vacancy Management
          </span>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <Bars3Icon className="h-6 w-6 hover:text-blue-500" aria-hidden="true" />
          </button>
        </div>
        {/* Dialog Code */}
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Yang Khor Private Ltd</span>
                <img className="h-16 w-auto" src="assets/logo.PNG" alt="company logo" />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <XMarkIcon className="h-6 w-6 hover:text-red-500" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                {/* Other Nav Links */}
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <div className="mt-28 md:mt-32 mx-auto w-5/6">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-800 to-blue-300 text-lg md:text-2xl">
          Vacancy Announcement For A Business Analyst
        </span>
        <br /><br />
        {/* Table */}
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={thStyle}>Sl. No</th>
              <th style={thStyle}>Designation</th>
              <th style={thStyle}>No. of Post</th>
              <th style={thStyle}>Type</th>
              <th style={thStyle}>Remarks</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={tdStyle}>1</td>
              <td style={tdStyle}>Business Analyst</td>
              <td style={tdStyle}>1 slot</td>
              <td style={tdStyle}>Full Time</td>
              <td style={tdStyle}>Preferable Someone With Experience</td>
            </tr>
          </tbody>
        </table>

        {/* Job Description */}
        <div className="text-sm md:text-base justify-center">
          We are looking for a motivated Business Analyst to join our software team. The role involves analyzing client requirements, developing Software Requirements Specifications (SRS), preparing technical proposals, managing documentation, and assisting with work planning. <br/>
          Interested candidates fulfilling the qualification and experiences
          criteria may send their applications along with all the required
          documents to the <span className="text-blue-500 font-bold">   
          <a href="mailto:info@yangkhor.com?subject=Inquiry&body=Hello%20Yangkhor%20Team,">info@yangkhor.com</a>

          </span> on or before <span className="text-blue-500 font-bold">15th October 2024</span>.
          Submit the copy of the following documents: <br/><br/>
          <span className="text-black-500 font-bold">ToR Attachment: </span>
          <br /><br />

          {/* ToR Attachment Links */}
          <br />
          <span className="text-black-500 font-bold">ToR Attachment (Business Analyst): </span>
          <button
            className="text-blue-500 font-bold"
            onClick={() => handleViewClick('assets/officedocs/BA position.pdf')}
          >
            View & Download
          </button>
          <br /><br />
          <span className="text-black-500 font-bold">Job Application Form: </span>
          <a className="text-blue-500 font-bold" href="assets/officedocs/Job Application_YPL_Final.pdf" download>Click here to view & download</a>
          <br /><br />

          <ul>
            <li>1. Curriculum Vitae/Resume</li>
            <li>2. Academic Transcript</li>
            <li>3. Training & Course Completion certificates</li>
            <li>4. Security Clearance Certificate</li>
            <li>5. Medical Certificate</li>
            <li>6. NOC from the current employer, if employed.</li>
            <li>7. Filled job application form.</li>
          </ul>
          <br /><br />
          For any queries related to the application submission, please contact our HR department at
          <span className="text-blue-500 font-bold">77719388/17390649/17368131</span> during office hours.
        </div>

        {/* Footer */}
        <div className="py-10">
          <hr className="border" />
          <span className="text-gray-700 text-xs">
            Please be informed that applications should be submitted only through the online system...
          </span>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default JobListings;
