import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import { auth } from "../config/firebase";
import { signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

const TopNav = () => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [user, setUser] = useState(null);
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = localStorage.getItem('theme');
        return savedTheme ? savedTheme === 'dark' : false;
    });

    const navigate = useNavigate();

    const fetchUser = () => {
        const currentUser = auth.currentUser;
        setUser(currentUser);
    };

    useEffect(() => {
        fetchUser();
    }, []);

    useEffect(() => {
        const root = document.documentElement;
        if (isDarkMode) {
            root.classList.add('dark');
            localStorage.setItem('theme', 'dark');
        } else {
            root.classList.remove('dark');
            localStorage.setItem('theme', 'light');
        }
    }, [isDarkMode]);

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const toggleTheme = () => setIsDarkMode(!isDarkMode);

    const logout = async () => {
        try {
            await signOut(auth);
            console.log('User logged out');
            navigate('/');
        } catch (err) {
            console.error('Error logging out:', err);
        }
    };

    return (
        <header className="bg-white dark:bg-gray-800 shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center space-x-4 w-full max-w-md">
                <FaSearch className="text-gray-500 dark:text-gray-300" />
                <input
                    type="text"
                    placeholder="Search..."
                    className="border rounded-lg py-1 px-3 flex-grow focus:outline-none focus:ring-2 focus:ring-blue-400 dark:bg-gray-700 dark:text-white"
                />
            </div>

            {/* Toggle Switch for Dark/Light Mode */}
            <div className="flex items-center space-x-3">
                <span className="text-gray-700 dark:text-gray-300">Dark Mode</span>
                <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                    <input
                        type="checkbox"
                        name="toggle"
                        id="toggle"
                        checked={isDarkMode}
                        onChange={toggleTheme}
                        className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                    />
                    <label
                        htmlFor="toggle"
                        className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer dark:bg-gray-600"
                    />
                </div>
            </div>

            <div className="relative flex items-center">
                <img
                    src="assets/backend_profile.gif"
                    alt="Profile"
                    className="w-10 h-10 rounded-full cursor-pointer"
                    onClick={toggleDropdown}
                />
                {user && (
                    <div className="ml-3 text-gray-700 dark:text-gray-300">
                        <p className="text-sm font-semibold">Hello, {user.email}!</p>
                    </div>
                )}
                {isDropdownOpen && (
                    <div className="absolute right-0 mt-2 bg-white dark:bg-gray-800 border rounded shadow-lg w-48">
                        <button
                            onClick={logout}
                            className="block px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 w-full text-left"
                        >
                            Sign Out
                        </button>
                    </div>
                )}
            </div>
        </header>
    );
};

export default TopNav;
