import React, { useState } from 'react';
import { firestoreDb as db } from '../config/firebase';
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faPhone, faBriefcase, faEnvelope, faVenusMars, faUser } from '@fortawesome/free-solid-svg-icons';

const AddStaff = () => {
    const [staff, setStaff] = useState({
        staff_CID: '',
        staff_contact: '',
        staff_designation: '',
        staff_email_id: '',
        staff_gender: '',
        staff_name: ''
    });

    const [errors, setErrors] = useState({
        staff_name: '',
    });

    const [alert, setAlert] = useState({ message: '', type: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStaff({ ...staff, [name]: value });
        validateField(name, value);
    };

    const validateField = (name, value) => {
        let error = '';
        if (name === 'staff_name' && !value) {
            error = 'Please provide your first name.';
        }
        setErrors({ ...errors, [name]: error });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validate all fields before submitting
        validateField('staff_name', staff.staff_name);

        if (errors.staff_name) {
            return; // Prevent submission if there are validation errors
        }

        try {
            await db.collection('office_staffs').add(staff);
            setAlert({ message: 'Staff added successfully!', type: 'success' });

            // Clear alert after 3 seconds
            setTimeout(() => {
                setAlert({ message: '', type: '' });
            }, 3000);

            setStaff({
                staff_CID: '',
                staff_contact: '',
                staff_designation: '',
                staff_email_id: '',
                staff_gender: '',
                staff_name: ''
            });

        } catch (error) {
            console.error('Error adding staff: ', error);
            setAlert({ message: 'Error adding staff.', type: 'error' });

            // Clear alert after 3 seconds
            setTimeout(() => {
                setAlert({ message: '', type: '' });
            }, 3000);
        }
    };

    return (
        <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
            <Sidebar />
            <div className="flex-1 flex flex-col">
                <TopBar />
                <main className="flex-1 p-6 overflow-y-auto">
                    <h2 className="text-2xl font-bold mb-4 flex items-center dark:text-gray-300">
                        Add Staff
                        {/* Tailwind Alert Box */}
                        {alert.message && (
                            <div className={`ml-4 p-2 text-sm rounded-lg ${alert.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`} role="alert">
                                {alert.message}
                            </div>
                        )}
                    </h2>

                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="flex items-center border p-2 rounded w-full">
                            <FontAwesomeIcon icon={faIdCard} className="mr-2 text-gray-500" />
                            <input
                                type="text"
                                name="staff_CID"
                                value={staff.staff_CID}
                                onChange={handleChange}
                                placeholder="Staff CID"
                                className="w-full outline-none"
                            />
                        </div>

                        <div className="flex items-center border p-2 rounded w-full">
                            <FontAwesomeIcon icon={faPhone} className="mr-2 text-gray-500" />
                            <input
                                type="text"
                                name="staff_contact"
                                value={staff.staff_contact}
                                onChange={handleChange}
                                placeholder="Staff Contact"
                                className="w-full outline-none"
                            />
                        </div>

                        <div className="flex items-center border p-2 rounded w-full">
                            <FontAwesomeIcon icon={faBriefcase} className="mr-2 text-gray-500" />
                            <input
                                type="text"
                                name="staff_designation"
                                value={staff.staff_designation}
                                onChange={handleChange}
                                placeholder="Staff Designation"
                                className="w-full outline-none"
                            />
                        </div>

                        <div className="flex items-center border p-2 rounded w-full">
                            <FontAwesomeIcon icon={faEnvelope} className="mr-2 text-gray-500" />
                            <input
                                type="email"
                                name="staff_email_id"
                                value={staff.staff_email_id}
                                onChange={handleChange}
                                placeholder="Staff Email ID"
                                className="w-full outline-none"
                            />
                        </div>

                        <div className="flex items-center border p-2 rounded w-full">
                            <FontAwesomeIcon icon={faVenusMars} className="mr-2 text-gray-500" />
                            <input
                                type="text"
                                name="staff_gender"
                                value={staff.staff_gender}
                                onChange={handleChange}
                                placeholder="Staff Gender"
                                className="w-full outline-none"
                            />
                        </div>

                        <div className="border p-2 rounded w-full">
                            <div className="flex items-center">
                                <FontAwesomeIcon icon={faUser} className="mr-2 text-gray-500" />
                                <input
                                    type="text"
                                    name="staff_name"
                                    value={staff.staff_name}
                                    onChange={handleChange}
                                    placeholder="Staff Name"
                                    className={`w-full outline-none ${errors.staff_name ? 'border-red-500' : ''}`}
                                />
                            </div>
                            {errors.staff_name && (
                                <p className="mt-2 text-pink-600">
                                    {errors.staff_name}
                                </p>
                            )}
                        </div>

                        <button type="submit" className="bg-blue-500 text-white p-2 rounded w-full">
                            Add Staff
                        </button>
                    </form>
                </main>
            </div>
        </div>
    );
};

export default AddStaff;
