import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const WebCard = ({ ImageHref, title, description, button, buttonHref }) => {
  return (
    <>
      <div
        className={`w-full ring-2 bg-gray-50 ring-gray-100 hover:shadow-lg pt-5 px-5 rounded-md`}
      >
        <div className="relative mb-12">
          <div className="overflow-hidden rounded-[10px]">
            <img src={ImageHref} alt="portfolio" className="w-full" />
          </div>
          <div className="relative z-10 mx-7 -mt-20 rounded-lg bg-gray-50 ring-1 ring-gray-100 py-[34px] px-3 text-center">
            <h3 className="mb-5 text-xl font-bold">{title}</h3>
            <p className="mb-4 text-gray-700 italic text-opacity-80 text-base leading-8 text-justify">
              {description}
            </p>
            <a
              href={buttonHref}
              target="_blank"
              rel="noreferrer"
              className="hover:bg-blue-500 inline-block rounded-md border border-stroke py-[10px] px-7 text-sm font-medium transition hover:text-white"
            >
              {button}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default function HRSolutions() {
  return (
    <div>
      <Header />
      <div className="pt-24 md:pt-36 text-center">
        <span className="bg-clip-text text-xl md:text-3xl font-bold text-transparent bg-gradient-to-r from-primary to-accent">
          HR Solutions
        </span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 px-5 gap-10 my-10">
        <WebCard
          ImageHref="assets/zest.PNG"
          title="Zest System development and enhancement"
          description="Civil Service Information System (CSIS) a vital Goverment central human resource system which manages the data of all civil servants from recruitment to separation."
          button="Visit site"
          buttonHref="https://zest.rcsc.gov.bt/"
        />
        <WebCard
          ImageHref="assets/csoa.PNG"
          title="CSOA-IMS System Enhancement"
          description="CSOA website revamping and CSOA system development."
          button="Visit site"
          buttonHref="http://www.csoa.org.bt/public/w"
        />
      </div>
      <Footer />
    </div>
  );
}
